import React, { Component } from "react";
import { Button, Grid, Header, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import { AdAregionSwipetoslide } from "../components/react-slick/ad-aregion-swipetoslide";
import { AdBcdregionSwipetoslide } from "../components/react-slick/ad-bcdregion-swipetoslide";
import "../styles/bg.css";
import { getFullPath } from "../controllers/kaisaku-api";
import Lambda from "../controllers/utilities/aws-lambda";
import {showFJB} from "./sdk-ad-noodle-entr";

import login01 from "../images/noodles/4/landscape/1.jpg";
import login02 from "../images/noodles/4/landscape/2.jpg";
import login03 from "../images/noodles/4/landscape/3.jpg";
import login04 from "../images/noodles/4/landscape/4.jpg";
import login05 from "../images/noodles/4/landscape/5.jpg";
import login06 from "../images/noodles/4/landscape/6.jpg";
import login07 from "../images/noodles/4/landscape/7.jpg";
import login08 from "../images/noodles/4/landscape/8.jpg";
import login09 from "../images/noodles/4/landscape/9.jpg";
import login10 from "../images/noodles/4/landscape/10.jpg";
import login11 from "../images/noodles/4/landscape/11.jpg";
import login12 from "../images/noodles/4/landscape/12.jpg";
import login13 from "../images/noodles/4/landscape/13.jpg";
import login14 from "../images/noodles/4/landscape/14.jpg";
import login15 from "../images/noodles/4/landscape/15.jpg";
import login16 from "../images/noodles/4/landscape/16.jpg";
import login17 from "../images/noodles/4/landscape/17.jpg";
import login18 from "../images/noodles/4/landscape/18.jpg";
import login19 from "../images/noodles/4/landscape/19.jpg";
import login20 from "../images/noodles/4/landscape/20.jpg";
import login21 from "../images/noodles/4/landscape/21.jpg";
import login22 from "../images/noodles/4/landscape/22.jpg";
import login23 from "../images/noodles/4/landscape/23.jpg";
import login24 from "../images/noodles/4/landscape/24.jpg";
import login25 from "../images/noodles/4/landscape/25.jpg";
import login26 from "../images/noodles/4/landscape/26.jpg";
import login27 from "../images/noodles/4/landscape/27.jpg";
import login28 from "../images/noodles/4/landscape/28.jpg";
import login29 from "../images/noodles/4/landscape/29.jpg";
import login30 from "../images/noodles/4/landscape/30.jpg";
import login31 from "../images/noodles/4/landscape/31.jpg";
import iconClose from "../images/account/close2.png";
import cookie from 'react-cookies';


export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showInfo: false,
            monthCount: 0,
            allcount: 0,
            url: "",
            index: 1,
            startTime: 0,
            endTime: 0,
            transactionId: '',
            userId: '',
            gameId: '',
            eventId: '',
            accessToken: '',
            nickname: '',
            createdAt: '',
            expireAt: '',
            expireAt_TTL: '',
            divStyle: ''
        };

        let { showInfo, monthCount, allcount, url, index, startTime, endTime, transactionId, eventId, userId, gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL } = formatQuery(this.props.location.search);
        if (parseInt(allcount) === 0) {
            allcount = 1;
        }
        this.state.showInfo = showInfo;
        console.log("showInfo =" + showInfo);
        this.state.monthCount = monthCount;
        this.state.allcount = allcount;
        this.state.url = url;

        this.state.index = index;
        console.log("index =" + index);
        this.state.startTime = startTime;
        this.state.endTime = endTime;
        this.state.transactionId = transactionId;

        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        // 保存弹出时间
        // if (!cookie.load("entronly" + accessToken + "index" + index)) {
        //     cookie.save("entronly" + accessToken + "index" + index, "entronly" + accessToken + "index" + index, {
        //         path: "/",
        //         expires: new Date(new Date().getTime() + 0.5 * 3600 * 1000)
        //     });
        // }
    }

    async componentDidMount() {
        // if (this.state.showInfo === "true"){
        //     console.log("更新累登数据");
        //     const data = await this.userSignIn(this.state.accessToken);
        //     console.log(data);
        //     this.setState({
        //         monthCount: data.monthCount,
        //         allcount: data.allcount
        //     });
        // }
    }

    async userSignIn(accessToken) {
        const res = await Lambda.post(getFullPath(`profile/userSignIn`), null, accessToken);
        if (res.status === 200) {
            return res.data;
        } else {
            return "";
        }
    }

    async closeAd() {
        if (this.state.showInfo === "true"){
            console.log("更新累登数据");
            const data = await this.userSignIn(this.state.accessToken);
            console.log(data);
            this.setState({
                monthCount: data.monthCount,
                allcount: data.allcount
            });
        }
    }

    render() {
        let noodle = login01;
        switch (parseInt(this.state.monthCount)) {
            case 2:
                noodle = login02;
                break;
            case 3:
                noodle = login03;
                break;
            case 4:
                noodle = login04;
                break;
            case 5:
                noodle = login05;
                break;
            case 6:
                noodle = login06;
                break;
            case 7:
                noodle = login07;
                break;
            case 8:
                noodle = login08;
                break;
            case 9:
                noodle = login09;
                break;
            case 10:
                noodle = login10;
                break;
            case 11:
                noodle = login11;
                break;
            case 12:
                noodle = login12;
                break;
            case 13:
                noodle = login13;
                break;
            case 14:
                noodle = login14;
                break;
            case 15:
                noodle = login15;
                break;
            case 16:
                noodle = login16;
                break;
            case 17:
                noodle = login17;
                break;
            case 18:
                noodle = login18;
                break;
            case 19:
                noodle = login19;
                break;
            case 20:
                noodle = login20;
                break;
            case 21:
                noodle = login21;
                break;
            case 22:
                noodle = login22;
                break;
            case 23:
                noodle = login23;
                break;
            case 24:
                noodle = login24;
                break;
            case 25:
                noodle = login25;
                break;
            case 26:
                noodle = login26;
                break;
            case 27:
                noodle = login27;
                break;
            case 28:
                noodle = login28;
                break;
            case 29:
                noodle = login29;
                break;
            case 30:
                noodle = login30;
                break;
            case 31:
                noodle = login31;
                break;
        }

        const mode = detectOrient();
        let width = "80vw";
        let height = "85vw";
        let bottom = "8%";
        if (mode === "LANDSCAPE") {
            width = "80vh";
            height = "90vh";
            bottom = "13%";
        }

        return (
            <Grid textAlign='center' style={{
                width: '100vw',
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{
                    width: '100vw',
                    height: "100vh"
                }}>
                    <Modal
                        style={{ width: `${width}`, height: `${height}`, borderRadius: '10px 10px 10px 10px' }}
                        open
                    >
                        <div style={{ width: `${width}` }} >
                            <div style={{ height: "100%", borderRadius: '10px 10px 10px 10px' }}
                            >
                                {(mode === "LANDSCAPE") && (<Image src={noodle} style={{ width: '80vh', height: '80vh', objectFit: 'fill', borderRadius: '10px 10px 0px 0px' }} />)}
                                {(mode === "PORTRAIT") && (<Image src={noodle} style={{ width: '80vw', height: '80vw', objectFit: 'fill', borderRadius: '10px 10px 0px 0px' }} />)}
                                <div style={{
                                    position: "absolute",
                                    top:"1%",
                                    left:"1%",
                                 }} onClick={async () => {                                    
                                    await this.closeAd();
                                    // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    // 去掉飞机杯，加回来
                                    AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                }}>
                                 <Image style={{
                                        height: "2.5vh",
                                        minHeight: "16px"
                                    }} centered
                                        src={iconClose}
                                    />
                                 </div>
                                <div style={{
                                    position: "absolute",
                                    bottom: `${bottom}`,
                                    right: "5%",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontStyle: "bold",
                                    width: "30px",
                                    textAlign: "center",
                                    textShadow: "1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000"
                                }}>{this.state.allcount}</div>
                            </div>

                            <div style={{
                                height: '41px', textAlign: 'center', fontSize: '1.35em', fontWeight: 'bold',
                                backgroundColor: "#57C532",
                                paddingTop: '10px',
                                borderRadius: '0px 0px 10px 10px',
                                opacity: '1'
                            }}
                                onClick={async () => {                                    
                                    await this.closeAd();
                                    // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    // 去掉飞机杯，加回来
                                    AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                }}
                            >
                                <span style={{ color: '#FFF' }}>立即簽到</span>
                            </div>
                        </div>

                    </Modal>
                </Grid.Column>
            </Grid>);
    }

});

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}